
.form {
    min-height: 90vh;
    padding: 0px !important;


    &__block {
        display: flex;
        justify-content: center;
        padding: 0 2vw;
        &__dangerous-message {
            width: 80%;
            margin: 20px 0;
            text-align: center;
            justify-content: center;
            padding: 30px 7vw;
            font-weight: bold;
            font-size: 18px;
            background-color: var(--color-background--attention);
            color: var(--color-text--dark-blue);
        }
    }

    &__start-answering {
        margin-top: 12vh;
        font-size: 22px;
        font-weight: bold;
        color: var(--color-text--dark-blue);
        justify-content: center;
    }

    &__succesful-message {
        text-align: center;
        padding: 8vh 5vw;
        font-size: 22px;
        font-weight: bold;
        color: var(--color-text--dark-blue);
        background-color: var(--color-admin-light-blue);
        justify-content: center;
    }

    &__page {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0;
        flex-direction: column;

        &-number {
            font-size: 20px;
            font-weight: bold;
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;

        &__page-number {
            font-weight: 600;
            font-size: 20px;
            color: var(--color-text--dark);
        }

        &__partners {
            // margin-top: 5vh;
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            column-gap: 2vw;
        }
    
        &__partners:first-child {
            flex: 1 1 30%;
        }
    
        &__title {
            font-size: 20px;
            font-weight: bold;
            color: var(--color-text--attention);
            width: 50%;
            text-align: center;
        }

        &__description {
            max-width: 60%;
            padding-bottom: 20px;
            font-size: 18px;
            text-align: justify;
            font-style: italic;
            color: var(--color-admin-dark-grey);
        }
    }

    &__content {
        background-color: var(--color-admin-light-blue);
        padding:0  20px;
    }

    &__listing {
        margin: 0 10px;
        outline: none;
        border: 1px solid var(--color-text--dark-blue);
        padding: 10px 20px;
        background-color: var(--color-background--navbar);
        color: var( --color-text--dark-blue);
        font-weight: 700;
        border-radius: 7px;
        margin-bottom: 30px;
    }

    &__listing:hover {
        background-color: var(--color-admin-light-blue);
    }
}