
@media screen and (max-width: 500px) {
    .answer__table {
    &__style {
      &__4, &__5 {
          th, td {
            padding: 10px;
            }
      }
  
      &__0, &__1 {
        th, td {
          padding: 10px 10px;
        }
      }
    }
  }
}
  