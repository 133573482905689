
.file-download {
    width: 100%;
    padding-top: 4vh;
    margin-bottom: 4vh;
    background-color: var(--color-admin-light-blue-darker);
    padding: 20px;

    &__title {
        padding: 20px;
        text-align: center;
        color: var(--color-admin-dark-blue);
        font-size: 20px;
        font-weight: bold;
    }

    &__input {
        color: var(--color-admin-dark-blue);
        margin-bottom: 4vh;
        border: 1px solid var(--color-background--navbar);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        color: var(--color-admin-dark-blue);
        background-color: var(--color-admin-light-blue);
        margin-bottom: 3vh;
        padding: 2vh 20px;
    }

    &__table__icon {
        color: var(--color-admin-dark-blue);
        height: 100%;
    }

    &__string {
        padding: 10px;
        border: 1px solid var(--color-background--attention);
        column-gap: 10px;
        display: flex;
        align-items: center;
    }

    
    &__image {
        width:100%;
        height: 150px;
    }
}