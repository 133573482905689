
@media screen and (max-width: 500px) {
    .last-page {
        &__block {
            display: flex;
            flex-direction: column;
            row-gap: 40px;

        }
    }
}