.question-section {
    padding: 3vh 2vw !important;
    background-color: var(--color-admin-light-blue-darker);

    &__manage {
        background-color: var(--color-admin-dark-blue-hover);
        padding: 2vh 2vw !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__title {
            font-size: 20px;
            color: var(--color-background--light);
            font-weight: bold;
        }
    }

    &__title {
        font-size: 20px;
        color: var(--color-admin-dark-blue);
        margin: 15px 0;
    }

    &__input {
        width: 60%;
        margin-bottom: 20px;
        color: var(--color-admin-dark-blue);
        padding: 5px 10px;
        border: 1px solid var(--color-admin-dark-blue-hover);
        border-radius: 7px;
    }

    &__input:focus {
        outline: none !important;
    }

    &__alert-message {
        display: none;
    }

    &__alert-message__show {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3vh 2vw !important;
        background-color: var(--color-background--attention);
        font-size: 18px;
        font-weight: bold;
        color: var(--color-admin-dark-blue);
    }

}
