

.second-level {
    min-height: 65vh;
    margin-bottom: 30px;

    &__title {
        padding: 40px 20px 0 20px;
        display: flex;
        justify-content: center;

        &--text {
            width: 100%;
            padding: 10px 20px;
            font-weight: bold;
            background-color: var(--color-second-page--title);
            color: var(--color-text--attention);
            text-align: center;
            font-size: 26px;
        }
    }

    &__second--title {
        font-weight: lighter;
        color: var(--color-text--attention);
        text-align: center;
        font-size: 22px;
        margin-bottom: 10px;
    }

    &__description {
        display: flex;
        margin: 30px 0;

        &--text {
            font-weight: lighter;
            font-size: 20px;
            color: var( --color-text--dark-blue);
            text-align: justify;
        }
    }

    &__publication--sections {
        display: flex;
        margin: 30px 0;
        flex-direction: column;

        &--section {
            display: flex;
            text-decoration: none;
            margin-top: 10px;
        }

        &--index {
            font-weight: bold;
            font-size: 20px;
            color: var(--color-text--attention);
        }

        &--title {
            margin-left: 5px;
            border-bottom: 1px solid var(--color-text--dark-blue);
            text-align: justify;
            font-weight: bold;
            font-size: 20px;
            color: var( --color-text--dark-blue);
        }
    }

    
    &__publications {
        display: flex;
        margin: 30px 0;
        flex-direction: column;

        &--item {
            display: flex;
            margin-top: 10px;
        }

        &--index {
            font-weight: bold;
            font-size: 20px;
            color: var(--color-text--attention);
        }

        &--title {
            margin-left: 5px;
            text-align: justify;
            font-size: 20px;
            color: var( --color-text--dark-blue);
        }
    }

    &__forms, &__persons {
        margin: 30px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &__block {
            width: 100%;
            padding: 20px 0;
            row-gap: 15px;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;
        }
    }

    &__personality {
        margin: 40px 0;
        font-size: 20px;
        text-align: justify;
        color: var(--color-text--dark-blue);

        &__title {
            font-weight: bold;
            margin-top: 25px;
            margin-bottom: 5px;
            font-size: 24px;
        }

        &__info {
            align-items: end;
            display: flex;

            &--personal {
                padding-left: 30px;
                display: flex;
                flex-direction: column;
            }
        }
    }
}