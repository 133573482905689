@media screen and (max-width: 1250px) {

    .top-navbar {

        &__links {
            &--large {
                display: none !important;
            }
           
            &--small {
                display: flex !important;
            }
        }
    }
}


@media screen and (max-width: 700px) {

    .top-navbar {
        &--row {
            width: 100% !important;
        }

        &__links {
            
            &__navigation-btn {
                margin-right: 0 !important;
            }

            &__pull-btn {
                margin-right: 0 !important;
                padding: 7px 10px !important;
            }
        }
    }
}