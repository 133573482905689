.admin-header {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: var(--color-background--navbar);
    padding: 15px 5vw !important;

    &__person {
        font-size: 18px;
        color: var(--color-admin-dark-blue);
    }

    &__go-out {
        display: flex;
        align-items: center;
        margin-left: 30px;
        font-size: 26px;
        color: var(--color-admin-dark-blue);
        cursor: pointer;

        &__text {
            font-weight: bold;
            margin-right: 10px;
            font-size: 18px;
        }
    }

    &__go-out:hover {
        color: var(--color-text--attention)
    }
}