

.save-button {

    &__container__unvisible__true, &__container__unvisible__false{
        display: none;
    }
    
    &__container__disabled__true,  &__container__disabled__false{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
       
        &__confirmation {
            filter: grayscale(0.29) opacity(0.43);
            margin-top: 2vh;
            display: flex;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 20px;
            align-items: center;
            justify-content: center;
            background-color: var(--color-admin-light-blue);
            color: var(--color-text--attention);
            border-radius: 7px;
            border: 1px solid var(--color-text--attention);
            width: fit-content;
        }
        
    }
        

    &__container__active__true, &__container__active__false {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__confirmation {
            cursor:pointer;
            margin-top: 2vh;
            display: flex;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 20px;
            align-items: center;
            justify-content: center;
            background-color: var(--color-admin-light-blue);
            color: var(--color-text--attention);
            border-radius: 7px;
            border: 1px solid var(--color-text--attention);
            width: fit-content;
        }


        &__confirmation:hover {
            box-shadow: inset 0px 3px 10px 2px rgba(0, 0, 0, 0.25);
        }
    }

    &__container__question-button {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__true__confirmation {
            filter: grayscale(0.29) opacity(0.43);
            display: flex;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 20px;
            align-items: center;
            justify-content: center;
            background-color: var(--color-admin-light-blue);
            color: var(--color-text--attention);
            border-radius: 7px;
            border: 1px solid var(--color-text--attention);
            width: fit-content;
        }

        &__false__confirmation {
            cursor:pointer;
            display: flex;
            font-size: 18px;
            font-weight: bold;
            padding: 10px 20px;
            align-items: center;
            justify-content: center;
            background-color: var(--color-admin-light-blue);
            color: var(--color-text--attention);
            border-radius: 7px;
            border: 1px solid var(--color-text--attention);
            width: fit-content;
        }


        &__false__confirmation:hover {
            box-shadow: inset 0px 3px 10px 2px rgba(0, 0, 0, 0.25);
        }
    }
}