
.answer-section {
    padding: 0 6vw !important;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 20px;
        color: var(--color-text--dark-blue);
        padding: 15px 30px;
        border-bottom: 1px solid var(--color-text--dark-blue);
    }

    &__description {
        width: 80%;
        text-align: justify;
        font-size: 18px;
        color: var(--color-dark-grey);
        margin: 20px 0;
        font-style: italic;
    }

    &__content {
        width: 100%;
    }
}

