
.add-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-admin-light-blue);
    margin: 20px 0;
    padding: 3vh 2vw;

    &__true {
        filter: grayscale(0.29) opacity(0.43);
    }

    &__title {
        margin-bottom: 20px;
        font-size: 20px;
        color: var(--color-admin-dark-blue);
    }

    &__button {
        border: none !important;
        display: flex;
        background-color: var(--color-admin-dark-blue);
        color: var(--color-background--light);
        height: 34px;
        width: 34px;
        justify-content: center;
        align-items: center;
        border-radius: 7px;
    }

    &__button:hover {
        background-color: var(--color-admin-dark-blue-hover);
    }
}