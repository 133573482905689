
.top-navbar {
    opacity: 1;
    transition: all 0.4s ease-in-out;
    background-color: var(--color-background--navbar);
    z-index: 3;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 10px 0;
    height: 85px;


    &--row {
        width: 90%;
        align-items: center;
        justify-content: center;
    }

    &--logo {
        display: flex;
        align-items: center;
        height: fit-content;
        
        &-image {
            height: 70px;
        }
    }

    &--wrapper {
        display: flex;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
    }

    &__links {
        display: flex;
        align-items: center;

        &--large {
            display: flex;
            align-items: center;
        }

        &--small {
            display: none;
            max-width: fit-content;
        }

        &__navigation-btn {
            font-size: 32px;
            margin-right: 20px;
            display: flex;
            align-items: center;
            padding: 7px;
            border: none;
        }

        &__navigation-btn:focus {
            border: none;
        }
    
        a {
            font-weight: bold;
            margin-right: 20px;
            text-decoration: none;
            color: var(--color-text--dark-blue);
            font-size: 20px;
        }

        a:hover {
            color: var(--color-text--attention);
        }

        &__pull-btn {
            margin-right: 10px !important;
            padding: 7px 20px;
            background-color: var(--color-background--light);
            border-radius: 7px;
            color: var(--color-text--attention) !important;
            border: none !important;
        }

        &__pull-btn:hover {
            color: var(--color-text--dark-blue) !important;
        }

        &__dropdown {
            border-top: 2px solid var(--color-background--light);
            background-color: var(--color-background--navbar);
            transition: all 0.4s ease-in-out;
            z-index: 4;
            padding: 0;
            height: fit-content;
            top: 85px;
            z-index: 3;
            position: fixed;
            display: none;
            justify-content: center;

            &__row {
                width: 90%;
                justify-content: flex-end;

                &--wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-right: 25px;
                    padding-left: 15px;
                    padding-right: 15px;
                }

                a, div {
                    width: fit-content !important;
                    text-align: left;
                    color: var(--color-text--dark-blue);
                    text-decoration: none;
                    padding: 10px;
                    font-weight: bold;
                    font-size: 18px;
                }

                a:hover {
                   color: var(--color-text--attention);
                }
            }
        }
    }
}




