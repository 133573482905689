

.footer {
    min-height: 30vh;
    background-color: var(--color-border);
    height: fit-content;
    position: relative;
    justify-content: center;
    display: flex;

    .footer-decorator {
        text-align: center;
        padding: 5vh 5vw;
        justify-content: center;
        display: flex;
        row-gap: 40px;
        flex-direction: column;

        .site-name {
            color: var(--color-background--light);
            font-size: 22px;
            font-weight: bold;
        }

        .footer-cell {
            .local-title {
                font-size: 20px;
                font-weight: bold;
                color: var(--color-background--light-blue);
            }
            .footer-text {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                font-size: 18px;
                color: var(--color-background--light-blue);

                &__contact {
                    margin-left: 10px;
                    color: var(--color-background--light-blue)
                }

                a {
                    font-size: 18px;
                    text-decoration: none;
                    color: var(--color-background--light-blue);
                }
                a:hover {
                    text-decoration: none;
                    color: var(--color-border);
                }
            }
        }
    }
        
}