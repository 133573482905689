
.admin {

    &__temp {
        font-size:20px;
    }

    &__btn-group {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }

    &__deleted {
        color: var(--color-admin-dark-grey) !important;
    }

    &__button {
        font-size: 18px;
        text-decoration: none;
        transition-property: background-color, color;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
        cursor: pointer;
        margin-bottom: 4vh;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 10px 20px;
        border-radius: 7px;
        background-color: var(--color-background--navbar);
        color: var(--color-admin-dark-blue);
        font-weight: bold;
        border: none !important;
    }

    &__button__get {
        font-size: 18px;
        text-decoration: none;
        transition-property: background-color, color;
        transition-duration: 0.2s;
        transition-timing-function: ease-out;
        cursor: pointer;
        width: fit-content;
        padding: 10px 20px;
        border-radius: 7px;
        background-color: var(--color-background--navbar);
        color: var(--color-admin-dark-blue);
        font-weight: bold;
        border: none !important;
        margin-bottom: 0 !important;
        background-color: var(--color-admin-light-blue);
    }

    &__button__get:hover {
        color: var(--color-admin-dark-blue);
        background-color: var(--color-background--navbar) !important;
    }

    &__button:hover {
        background-color: var(--color-admin-light-blue);
        color: var(--color-admin-dark-blue);
    }

    &__mainpage {
        background-color: var(--color-background--light);
        color: var( --color-admin-dark-blue);
    
        &__title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 15px;
        }
    
        &__text {
            font-size: 18px;

            &--title {
                margin-top: 20px;
                width: fit-content;
                padding: 0 10px;
                background-color: var(--color-admin-light-blue);
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: 600;
            }

            &--main {
                margin-bottom: 10px;
                font-weight: 500;
            }

            &--note {
                margin-top: 10px;
                font-weight: 600;
                font-style: italic;
            }

            &__example {
                &--title {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }
    }
    
    &-decorator {
        padding: 8vh 5vw !important;
    
            &__title {
                font-weight: bold;
                margin-bottom: 7vh;
                height: fit-content;
                text-align: center;
                width: 100%;
                background-color: var(--color-admin-light-blue);
                padding: 10px 0;
                font-size: 22px;
                color: var(--color-admin-dark-blue);
            }
    }
    
    &__file-download {
        width: 100%;
        padding-top: 4vh;

        &__input {
            color: var(--color-admin-dark-blue);
            margin-bottom: 4vh;
            border: 1px solid var(--color-background--navbar);
        }

        &__list {
            color: var(--color-admin-dark-blue);
            background-color: var(--color-admin-light-blue);
            margin-bottom: 3vh;
            padding: 2vh 20px;
        }
    }

    &-menu {
        &__title {
            font-size: 20px;
            font-weight: bold;
            width: 90%;
            display: block;
            padding: 10px 20px;
            border: 1px solid var(--color-admin-light-blue);
            color: var(--color-admin-dark-blue);
            background: -webkit-linear-gradient(top, var(--color-background--light) 0%, var(--color-admin-light-grey) 100%);
        }
    
        &__title:hover {
            color: var(--color-admin-dark-blue-hover);
            background: var(--color-background--light);
        }
    
        &__names  {
            width: 90%;
        }
    }

    &__table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid var(--color-admin-light-blue);
        font-size: 18px;

        th, tr {
            text-align: center;
            border: 1px solid var(--color-admin-light-blue)
          }
        
        td, th {
            word-break: break-word;
            padding: 10px;
            border: 1px solid var(--color-admin-light-blue)
        }
        tbody tr:nth-child(odd) {
        background-color: var(--color-admin-grey-opacity);
        }
        tbody tr:nth-child(even) {
        background-color: var(--color-admin-blue-opacity);
        }
    }

    &__form {

        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__button, &__button-light {
            display: flex;
            background-color: var(--color-admin-dark-blue);
            color: var(--color-background--light);
            height: 34px;
            width: 34px;
            justify-content: center;
            align-items: center;
            border-radius: 7px;
            cursor: pointer;
            border: none !important;

            &__plus__none {
                display: none;
            }

            &__plus__true {
                margin-bottom: 20px;
                display: block;
                filter: grayscale(0.29) opacity(0.43);
            }

            &__plus__true {
                margin-bottom: 20px;
                display: block;
            }

        }
        &__button:hover {
            background-color: var(--color-admin-dark-blue-hover);
        }

        &__button-light {
            background-color: var(--color-background--light);
            color: var(--color-admin-dark-blue);
        }

        &__button-light:hover {
            background-color: var(--color-background--light);
        }

        &__input {
            width: 100%;
            margin-bottom: 20px;
            color: var(--color-admin-dark-blue);
            padding: 5px 10px;
            border: 1px solid var(--color-admin-dark-blue);
            border-radius: 7px;

            &-title {
                margin-bottom: 20px;
                font-size: 20px;
                color: var(--color-admin-dark-blue);
            }
        }

        &__input:focus {
            outline: none !important;
        }
    }

    &__article {

        &__textarea {
            width: 100%;
            padding: 5px;
            background-color: var(--color-background--light);
            border-radius: 7px;
            border: 1px solid var(--color-admin-dark-grey) !important;
            height: 20vh;
        }

        &__textarea:focus {
            outline: none !important;
            border: 1px solid var(--color-admin-dark-blue-hover) !important;
        }

        &__input {
            width: 100%;
            margin-bottom: 20px;
            color: var(--color-admin-dark-blue);
            padding: 5px 10px;
            border: 1px solid var(--color-admin-dark-blue);
            border-radius: 7px;

            &-title {
                margin-top: 20px;
                margin-bottom: 10px;
                font-size: 20px;
                font-weight: bold;
                color: var(--color-admin-dark-blue);
            }
        }

        &__input:focus {
            border: 1px solid var(--color-admin-dark-blue-hover) !important;
            outline: none !important;
        }
    }
}
