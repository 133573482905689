.sidebar {
    left: 0;
    height: inherit;
    width: fit-content;
    background-color: var(--color-admin-dark-blue);
    padding: 15px 15px;


    &__wrapper {
        text-align: center;
        width: 100%;
        font-weight: 600;
    }

    &__site-name {
        color: var(--color-background--light);
        text-decoration: none;
        font-size: 22px;
    }

    &__site-name:hover {
        text-decoration: none;
        color: var(--color-background--light);
    }

    &__person {
        text-align: center;
        width: 100%;
        font-size: 18px;
        color: var(--color-background--light);
        margin: 30px 0;

        &__role {
            font-size: 14px;
            margin-top: 10px;
        }
    }

    &__menu {
        margin-top: 7vh;
        position: relative;

        &__link {
            text-decoration: none;
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 10px 50px 10px 30px;
            font-size: 1.25rem;
            font-weight: 500;
            color: var(--color-background--navbar);
            transition: color 0.1s ease-in-out;

            &__icon {
                display: flex;
                align-items: center;
                margin-right: 1rem;
            }
        }

        &__item:hover {
            background-color: var(--color-admin-dark-blue-hover);
            color: var(--color-background--light);
        }
    }
}