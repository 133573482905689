@media screen and (max-width: 500px) {
    .form {
        
        &__start-answering {
            margin: 40px 0;
            font-size: 22px;
        }
    
        &__succesful-message {
            padding: 8vh 5vw;
            font-size: 22px;
        }
    
        &__container {
            &__partners {
                margin-right: 40px;
                margin-left: 40px;
                width: 70%;
                column-gap: 20px;
                row-gap: 20px;
                .partner-form {
                    width: 30%;
                    max-height: fit-content;
                }
            }
        
            &__title {
                width: 70%;
            }
    
            &__description {
                max-width: 90%;
            }
        }
    
        &__content {
            padding: 0 20px;
        }
    
        &__listing {
            margin: 0 10px;
            padding: 10px 20px;
            margin-bottom: 30px;
        }
    
        &__listing:hover {
            background-color: var(--color-admin-light-blue);
        }
    }
}
  

@media screen and (max-width: 1200px) {
    .form {
        &__block {
            padding: 0;
            &__dangerous-message {
                width: 100%;
            }
        }
        &__container {
            &__partners {
                width: 70%;
                column-gap: 20px;
                row-gap: 20px;
            }
        
            &__title {
                width: 70%;
            }
    
            &__description {
                max-width: 90%;
            }
        }
    }
}
  