

.person-card {
    width: 100%;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-background--light);
    text-decoration: none !important;
    border: 4px solid var(--color-background--navbar) !important;
    
    &__image {
        width: 100%;
        height: 100%;
        border-radius: 0.25rem;
        object-fit: contain;

        &--wrap {
            height: 200px;
        }
    }

    &__top {
        display: flex;
        flex-direction: column;
    }

    &--wrapper {
        display: flex;
        align-items: stretch;
    }

    &__name {
        font-weight: bold;
        word-wrap: break-word;
        color: var( --color-text--dark);
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
    }

    &__text {
        color: var(--color-text);
        text-align: center;
        margin-top: 10px;

        &--description {
            font-weight: bold;
            word-wrap: break-word;
            color: var( --color-dark-grey);
            font-size: 16px;
            text-align: center;
        }
    
    }
}

.person-card:hover {
    transition-duration: 1s;
    border: 4px solid var(--color-text) !important;
}
