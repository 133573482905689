
.change-group {
    display: flex;
    height: 100%;
    justify-content: end;
    align-items: center;
        &__icon {
            cursor: pointer;
            margin-left: 10px;
        }

        &__icon:hover {
            color: var(--color-admin-dark-blue-hover);
        }
}