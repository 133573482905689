.question-block {
    
    background-color: var(--color-admin-light-blue);
    margin-bottom: 20px;

    &__alert-message {
        display: none;
    }

    &__alert-message__show {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1vh 2vw !important;
        background-color: var(--color-background--attention);
        font-size: 18px;
        font-weight: bold;
        color: black;
    }

    &__block {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;

        &__row {
            padding: 10px 0px;
            font-weight: bold;
            color: var(--color-admin-dark-blue);
            font-size: 18px;
        }
        ul {
            background-color: var(--color-background--light);
            width: 45%;
            padding: 15px !important;
            margin: 0 !important;

            li {
                list-style-type: none;
            }
        }
    }

    &__string {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        //width: 50%;
    }

    &__block:first-child {
        flex: 50% 50%;
    }

    &__container {
        padding: 30px 25px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            width: 87%;
            padding: 5px 10px;
            border: 1px solid var(--color-admin-dark-grey);
            border-radius: 7px;
        }

        &__number {
            text-align: center;
            width: 11%;
            padding: 3px;
            border: 1px solid var(--color-admin-dark-grey);
            border-radius: 7px;
            margin-right: 2%;
        }

        &__title:focus, &__number:focus,  &__select:focus {
            outline: none !important;
        }

        &__select {
            color: var(--color-admin-dark-blue);
            padding: 5px 10px;
            border: 1px solid var(--color-admin-dark-grey);
            border-radius: 7px;
        }

        &__select:focus {
            outline: none !important;
        }
    }


    &__content {
        padding: 20px 25px;

        &__input {
            padding-bottom: 10px;
            margin-left: 10px;
            background-color: var(--color-admin-light-blue);
            border: none !important;
            border-bottom: 1px solid var(--color-admin-light-blue) !important;

            &__diff {
                width: 200px;
                padding: 10px;
                border: none !important;
                background-color: var(--color-background--light);
                border-bottom: 1px solid var(--color-admin-dark-grey) !important;
            }
        }

        &__input:focus, &__input__diff:focus {
            outline: none !important;
            border-bottom: 1px solid var(--color-admin-dark-grey) !important;
        }
    }

    &__different-option, &__one-answer {
        margin: 10px 0;
        display: flex;
        align-items:baseline;

        &__add-diff {
            margin-left: 10px;
        }

        &__add {
            margin-right: 10px;
        }

        &__add, &__add-diff {
            padding-bottom: 10px;
            color: var(--color-admin-dark-blue-hover);
            font-weight: bold;
            cursor: pointer;
            border-bottom: 1px solid var(--color-admin-light-blue);
        }

        &__add:hover, &__add-diff:hover {
            border-bottom: 1px solid var(--color-admin-dark-blue-hover);
        }

        &__table {
            margin-top: 20px;
            display: flex;
            width: 100%;
            justify-content: center;

            &__add {
                padding: 10px;
                text-align: center;
                color: var(--color-admin-dark-blue-hover);
                font-weight: bold;
                cursor: pointer;
                border-radius: 7px;
            }

            &__add:hover {
                background-color: var(--color-admin-light-blue);
            }
        }
    }

    &__different-option {
        margin: 20px 0;
    }

    &__footer {
        margin-top: 20px;
        border-top: 1px solid var(--color-admin-dark-grey);
        padding: 20px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__container {
            display: flex;
            align-items: center;
        }

        &__icons {
            cursor: pointer;
            padding: 0 20px; 
            border-right: 1px solid  var(--color-admin-dark-grey);
            color: var(--color-admin-dark-blue);
        }
        &__icons:hover {
           color: var(--color-admin-dark-blue-hover);
        }

        &__is-required {
            display: flex;
            padding-left: 20px;
            align-items: center;
            color: var( --color-admin-dark-blue);
            font-weight: bold;
        }
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}