@media screen and (min-width: 1200px) {
    .answer-section {
        padding: 0 2vw !important;
    
        &__description {
            width: 90%;
        }
    
        &__content {
            width: 90%;
        }
    }
}

@media screen and (max-width: 1200px) {
    .answer-section {
        padding: 0 !important;

    &__title {
        padding: 15px 30px;
    }

    &__description {
        width: 100%;
        margin: 20px 0;
    }

    &__content {
        width: 100%;
    }
}
}
  

/* ----------------------- Large desktop --------------------- */
@media screen and (min-width: 1600px) {
    .answer-section {
        &__content {
            width: 80%;
        }
        &__description {
            width: 80%;
        }
    }
    
}