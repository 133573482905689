
.question-type {
    &__0, &__1, &__4, &__5 {
        min-width: 250px;
        margin: 10px 0;
        display: flex;
        align-items:baseline;
    }
   
    &__input {
        padding: 5px;
        margin-left: 10px;
        background-color: var(--color-admin-light-blue);
        border: none !important;
        border-bottom: 1px solid var(--color-admin-light-blue) !important;

        &__diff {
            width: 230px;
            margin: 10px 0;
            margin-right: 20px;
            padding: 10px;
            border: none !important;
            background-color: var(--color-background--light);
            border-bottom: 1px solid var(--color-admin-dark-grey) !important;
        }
    }

    &__input:focus, &__input__diff:focus {
        outline: none !important;
        border-bottom: 1px solid var(--color-admin-dark-grey) !important;
    }

    &__2, &__3  {
        padding: 10px;
        width: 300px;
        height: 40px;
        background-color: var(--color-background--light-blue);
        border-bottom: 1px solid var((--color-admin-dark-grey)) !important;
    }

    &__choose {
        color: var(--color-text--attention);
    }

    &__table {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__row {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--color-admin-dark-grey);
        }

        &__column {
            padding-bottom: 0;
            margin-right: 30px;
        }

        &__column:focus {
            border-bottom: none !important;
        }

        &__icon {
            cursor: pointer;
            color: var(--color-admin-dark-blue);
        }

        &__icon:hover {
            color: var(--color-admin-dark-blue-hover);
        }
    }
}

