
.admin {
    &__add {
        margin-top: 6vh;
        background-color: var(--color-admin-light-blue);
        display: flex;
        color: var(--color-admin-dark-blue);
        flex-direction: column;
        padding: 3vh 20%;

        &__title {
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 20px;
            text-align: center;
        }

        &__field {
            width: 100%;
            padding: 5px;
            background-color: var(--color-background--light);
            border-radius: 7px;
            border: 1px solid var(--color-admin-dark-grey) !important;

            &__large {
                height: 60vh;
            }

            &__middle {
                height: 20vh;
            }
            
            &-block {
                margin: 10px 0;
            }
            &-title {
                margin-top: 15px;
                margin-bottom: 5px;
                font-weight: bold;
            }
            &__dangerous {
                background-color: var(--color-background--attention);
            }
        }
        
        &__field:focus {
            outline: none !important;
            border: 1px solid var(--color-admin-dark-blue-hover) !important;
        }

        &__button {
            display: flex;
            justify-content: center;
            margin-top: 40px;

            &__changed{
                border: 1px solid var(--color-admin-dark-blue-hover) !important;
            }

            &__changed:hover {
                background-color: var(--color-admin-dark-blue-hover) !important;
                color: var(--color-background--light);
            }
        }

        
    }
}

