
.last-page-admin {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--color-admin-light-blue-darker);
    padding: 20px;
    
    &__title {
        padding: 20px;
        color: var(--color-admin-dark-blue);
        font-size: 20px;
        font-weight: bold;
    }

    &__block {
        width: 100%;
        padding: 20px;
        background-color: var(--color-admin-light-blue);

    }

}