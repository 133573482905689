.modal {

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    animation-name: appear;
    animation-duration: 300ms;
    
    &__dialog {
        width: 100%;
        max-width: 550px;
        background: var(--color-background--light);
        position: relative;
        margin: 0 20px;
        max-height: 80vh;
        text-align: left;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: slide-in;
        animation-duration: 0.5s;
    }
  
    &__title {
      font-size: 24px;
      color: var(--color-admin-dark-blue-hover)
    }
    &__header,
    &__footer {
        display: flex;
        align-items: center;
        padding: 1rem;
    }
    
    &__header {
        border-bottom: 1px solid var(--color-admin-middle-grey);
        justify-content: space-between;
    }
    
    &__footer {
        border-top: 1px solid var(--color-admin-middle-grey);
        justify-content: flex-end;
    }
    
    &__close {
        cursor: pointer;
        font-size: 24px;
        color: var(--color-admin-dark-blue-hover)
    }
    
    &__body {
      overflow-y: auto;
    }
    
    &__content {
      color: var(--color-admin-dark-blue);
      padding: 1rem;
    }

    &__body::-webkit-scrollbar {
      width: 10px;
    }
    
    &__body::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px #333; 
    }
        
    &__body::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 2px #333; 
        background-color: var(--color-admin-blue);
    }
    
    &__body::-webkit-scrollbar-thumb:hover {
        background: var(--color-admin-grey-opacity);
    }
}

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slide-in {
    from {
      transform: translateY(-150px);
    }
    to {
      transform: translateY(0);
    }
  }