.switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 27px;
        margin-left: 20px;

    &__slider-round {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-admin-middle-grey);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    } 

    &__slider-round:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: var(--color-background--light);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
      }

      &__input {
        display:none;
    }

      &__input:checked + &__slider-round {
        background-color: var(--color-admin-dark-blue-hover);
      }
      
      &__input:focus + &__slider-round {
        box-shadow: 0 0 1px var(--color-admin-dark-blue-hover);
      }

      &__input:checked + &__slider-round:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }
}