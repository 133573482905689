
.main-page {

    background-color: var(--color-background--light);

    &__banner {
        border-bottom: 25px solid var(--color-border);
        padding: 0 !important;
        background: url('../../images/banner.jpg'); 
        background-repeat:  no-repeat;
        background-size: cover;
        background-clip: content-box;
        display: flex;
        align-items: center;
        height: 91vh;
    }

    &__quote {
        text-decoration: none;
        background-color: var(--color-background--light);
        opacity: 0.7;
        padding: 20px;
        border-radius: 7px;
        font-weight: bold;
        margin-top: -9vh;
        margin-left: 5vw;
        color: var(--color-text--dark-blue);
        font-size: 34px;
    }
    
    &__quote:hover {
        text-decoration: none;
        font-weight: bold;
        margin-top: -9vh;
        margin-left: 5vw;
        color: var(--color-text--attention);
        font-size: 34px;
    }

    &__block {
        display: flex;
        justify-content: center;

        &--row {
            row-gap: 20px;
            justify-content: center;
        }

        &--title {
            margin-top: 50px;
            font-size: 30px;
            color: var(--color-border);
            border-bottom: 2px solid var(--color-border);
            width: fit-content;
            font-weight: bold;
        }
    }

    &__advantages, &__comments, &__resources, &__partners{
        margin-top: 20px;
    }

    &__resources {
        border-radius: 7px;
        background-color: var(--color-light-grey);
        padding: 20px;
    }

    &__decorator {
        width: 90%;
    }

    &__about {
        margin-top: 20px;
        &--text {
            font-size: 22px;
            color: var(--color-text--dark);
            text-align: justify;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__partners {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;

        &--row {
            width: 90%;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            row-gap: 20px;
            column-gap: 20px;
        }

        &--image {
            width: 200px !important;
        }
    }

    &__partners:first-child {
        flex: 1 1 30%;
    }
}
