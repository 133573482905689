

    .name__point, .name__point-title {
        cursor:pointer;
        font-size: 18px;
        margin: 20px 0;
        margin-left: 20px;
        display: flex;
        align-items: center;
    }

    .name__point {
        margin-left: 40px;
    }

    .string-with-changes {
        font-weight: 600;
        font-size: 18px;
        padding: 5px 10px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--color-admin-light-blue);
        color: var(--color-admin-dark-blue);
        background: -webkit-linear-gradient(top, var(--color-background--light) 0%, var(--color-admin-light-grey) 100%);

        &__table {
            display: flex;
            justify-content: end;
            &__icon {
                cursor: pointer;
                margin-left: 10px;
            }
    
            &__icon:hover {
                color: var(--color-admin-dark-blue-hover);
            }
        }
    }

    .string-with-changes__icon-container {
        cursor: pointer;
        padding: 5px;
        display: block;
        height: inherit;
        border: 1px solid var(--color-admin-light-blue);
        background-color: var(--color-admin-dark-blue);
        color: var(--color-background--light);
    }

    .string-with-changes__icon-container:hover {
        background-color: var(--color-admin-dark-blue-hover);
    }