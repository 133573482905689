
/* ----------------------- Large desktop --------------------- */
@media screen and (min-width: 1600px) {

    .main-page {
        .block-title {
            padding-top: 2vh !important;
            font-size: 36px;
        }
        .content-go {
            min-height: fit-content;
            padding: 3vh 5vw;
        }
        .content-facts {
            min-height: fit-content;
        }    
    
        .content-text {
            min-height: fit-content !important;
            padding: 5vh 0 !important;
            .decorator {
                .about  {
                    padding: 4vh 0 !important;
                    .content-block {
                        .text-block {
                            width: 50%;
                            font-size: 18px;
                        }
                    }
                }
            }
            &__paragraf {
                font-size: 24px;
            }
            &__partners {
                padding: 10vh 6vw;
                row-gap: 11vh;
                column-gap: 6vw;
            }
    
            &__partners:first-child {
                flex: 1 1 30%;
            }
        }

        .carousel-content {
            .carousel-decorator {
                margin-top: 8vh;
                height: 90%;
                width: 90%;
            }
        }
        .content-go {
            padding: 6vh 5vw;
            row-gap: 10vh;
    
            .local-title {
                font-size: 36px;
            }

            a.take-part--btn {
                font-size: 32px;
                padding: 20px 30px;
            }
        }   
    }
}

/* ----------------------- Normal desktop --------------------- */

@media screen and (max-width: 1050px) {
    .main-page {
        &__quote {
            margin-right: 5vw;
            font-size: 28px;
        }
    }
}

/* ----------------------- Mobile --------------------- */

@media screen and (max-width: 750px) {
    
    .main-page {
        &__quote {
            margin-right: 5vw;
            font-size: 24px;
        }
    }
}