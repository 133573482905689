
.admin-access {
    font-family: "Nunito";
    display: flex;
    padding-top: 10vh;
    justify-content: center;
    height: 100vh;

   &__main {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 5vh 5vw;
    background-color: var(--color-background--light-blue);
    height: fit-content;
   }

    &__title {
        text-align: center;
        font-size: 28px;
        color: var( --color-text--dark);

        &-text {
            padding: 30px 0;
            font-size: 18px;
            color: var(--color-text--dark-blue);
        }
    }

    &__form {
        display: flex;
        flex-direction: column;

        &__field {
            width: 100%;
            border: 1px solid var(--color-text--dark);
            outline: none;
            border-radius: 7px;
            padding: 5px 10px;

            &-name {
                margin: 10px 0;
                color: var(--color-text--dark-blue);
                font-weight: bold;
            }
        }
        &__field:focus {
            border: 1px solid var(--color-text--dark); 
            background-color: var(--color-admin-light-blue)
        }

        &__button-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__button {
            font-size: 18px;
            border: none;
            margin-top: 40px;
            background-color: var(--color-text--dark);
            border-radius: 7px;
            padding: 5px 12px;
            text-align: center;
            color: var(--color-background--light);

            &-focused {
                background-color: var(--color-text--dark-blue);
            }
        }

        &__button:hover {
            background-color: var(--color-text--dark-blue);
            cursor: pointer;
        }
    }
}