

.advantage {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    
    &__image {
        width: 60px;
        height: 60px;
        height: auto;
        object-fit: contain;

        &--wrap {
            padding: 5px;
            border-radius: 50%;
            background-color: var( --color-background--light);
            border: 3px solid var(--color-admin-middle-grey);
            width: fit-content;
        }
    }

    &--wrapper {
        height: 100%;
        border-radius: 7px;
        background: var(--color-light-blue);
        padding: 20px;
    }

    &__name {
        font-weight: bold;
        word-wrap: break-word;
        color: var( --color-text--dark);
        font-size: 20px;
        text-align: left;
        margin-top: 20px;
    }

    &__text {
        color: var(--color-text);
        text-align: center;
        margin-top: 10px;

        &--description {
            font-weight: bold;
            word-wrap: break-word;
            color: var( --color-dark-grey);
            font-size: 16px;
            text-align: left;
            padding-bottom: 10px;
        }
    
    }
}

