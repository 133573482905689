
@media screen and (max-width: 500px) {
    .answer-option {
        &__choose {
            &-2 {
                width: 100%;
            }
        
        }
    }
}