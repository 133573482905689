
.answer__table {
  &__wrapper {
    overflow: auto;
  }

  &__style {
    &__4, &__5 {
        table-layout: fixed;
        background-color: var(--color-background--light);
        width: 100%;
        min-width: 700px;
        border-collapse: collapse;
        border: 1px solid var(--color-admin-light-blue);

        th, td {
          direction: ltr;
          word-break: break-word !important;
          height: fit-content;
          width: fit-content;
          padding: 10px;
          border: 1px solid var(--color-admin-light-blue)
          }

          th:nth-child(1) {
            width: 30%;
          }

        tbody tr:nth-child(odd) {
        background-color: var(--color-admin-grey-opacity);
        }
        
        tbody tr:nth-child(even) {
        background-color: var(--color-admin-blue-opacity);
        }
    }

    &__0, &__1 {
      direction: rtl;
      text-align: left;
      thead > tr > th {
        direction: ltr;
        color: var(--color-text--dark-blue);
        padding: 0;
      }

      th, td {
        direction: ltr;
        color: var(--color-text--dark-blue);
        padding: 5px 10px;
      }
    }

    &__2, &__3 {
      width: 100%;
    }
  }
}

